.floating-text-format-popup {
  display: flex;
  background: #fff;
  vertical-align: middle;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
  border-radius: 12px;
  will-change: transform;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
}

.floating-text-format-popup button {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 12px;
}