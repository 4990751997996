.split {
    display: flex;
    flex-direction: row;
}

.gutter {
    background-repeat: no-repeat;
    background-position: 100%;
}

.gutter.gutter-horizontal {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
    cursor: col-resize;
    background-position: center;
}

.divider {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
    cursor: col-resize;
    background-position: center;
}


.sash-wrap-line {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 1s;

    .line {
        display: block;
        height: 100%;
        width: 1px;
        background-color: rgba(#000, .2);
    }

    &.active {
        background-color: lightgray;
    }

    &.inactive {
        transition: none;
    }
}

.action-sash-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(#000, .1);

    .action {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 30px;
        background-color: rgba(#000, .3);
        color: rgba(#000, .5);
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
    }
}