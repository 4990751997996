.Theme__reportSection {
    background: none;
}
.Theme__reportSection:hover {
    background: rgba(255, 213, 0, 0.15);
}

.Theme__reportSectionNeedsAttention {
    background: rgba(255, 85, 0, 0.1);
}
.Theme__reportSectionNeedsAttention:hover {
    background: rgba(255, 85, 0, 0.15);
}

.Theme__reportSectionOverridden {
    background: rgba(0, 106, 255, 0.1);
}
.Theme__reportSectionOverridden:hover {
    background: rgba(0, 106, 255, 0.15);
}

.Theme__reportSectionHasComment:before, .Theme__reportSectionHasComment:after {
    content: "";
    position: absolute;
    height: 100%;
    width: 20px;
    top: 0px;
    background-image: radial-gradient(circle at center, #276EDF 5px, transparent 5px);
    background-size: 20px 20px;
    background-position: top center, bottom center;
    background-repeat: no-repeat;
}

.Theme__reportSectionHasComment:before {
    right: 0px;
}
.Theme__reportSectionHasComment:after {
    right: 0px;
}