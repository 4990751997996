#root {
    overflow: hidden;
    background-color: #FFFFFF;
}

html, body {
    overflow: hidden;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}