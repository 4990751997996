.KeyTermPopupPlugin_SummaryPopup {
    display: block;
    position: absolute;
    max-width: 50vw;
    min-width: 250px;
    background-color: #fff;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    z-index: 24;
    animation: show-input-box 0.4s ease;
}

.KeyTermPopupPlugin_SummaryPopup::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    margin-left: 0.5em;
    right: -1em;
    top: 0;
    left: calc(50% + 0.25em);
    box-sizing: border-box;
    border-color: transparent transparent #fff #fff;
    transform-origin: 0 0;
    transform: rotate(135deg);
    box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.05);
}

@keyframes show-input-box {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}