@media (max-width: 1024px) {
    .CommentPlugin_AddCommentBox {
        display: none;
    }
}

.CommentPlugin_CommentInputBox {
    display: block;
    position: absolute;
    width: 350px;
    min-height: 80px;
    background-color: #fff;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    z-index: 24;
    animation: show-input-box 0.4s ease;
}

.CommentPlugin_CommentInputBox::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    margin-left: 0.5em;
    right: -1em;
    top: 0;
    left: calc(50% + 0.25em);
    box-sizing: border-box;
    border-color: transparent transparent #fff #fff;
    transform-origin: 0 0;
    transform: rotate(135deg);
    box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.05);
}

@keyframes show-input-box {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}