.floating-report-section-toolbar {
  display: flex;
  background: #fff;
  vertical-align: middle;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  border-radius: 12px;
  will-change: transform;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
}

.floating-report-section-toolbar button {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 12px;
}