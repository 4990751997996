.gradient-border {
    --angle: 0deg;
    border-image: linear-gradient(var(--angle), #40a6ff, rgb(112, 0, 112)) 1;
    animation: 2s rotate linear infinite;
}

@keyframes rotate {
    to {
        --angle: 360deg;
    }
}

@property --angle {
    syntax: '<angle>';
    initial-value: 0deg;
    inherits: false;
}